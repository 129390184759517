import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Explanation } from "../../components/ExplanationModel";
import i18n from "../../locals/i18n";

interface ExplanationState {
  key: string;
}

const initialState: ExplanationState = {
  key: "",
};

const explanation = createSlice({
  name: "explanation",
  initialState,
  reducers: {
    showExplanation(state, { payload }: PayloadAction<string>) {
      state.key = payload;
    },
    hideExplanation(state) {
      state.key = "";
    },
  },
});

export const { showExplanation, hideExplanation } = explanation.actions;

export default explanation.reducer;

// All provided explanations
const explanations: { [key: string]: Explanation } = {
  oisField: {
    header: i18n.t("Explanations.oisHeader"),
    body: i18n.t("Explanations.oisBody"),
  },
  vsethField: {
    header: i18n.t("Explanations.vsethInformationHeader"),
    body: i18n.t("Explanations.vsethInformationBody"),
  },
  vsethFixedField: {
    header: i18n.t("Explanations.vsethFixedInformationHeader"),
    body: i18n.t("Explanations.vsethFixedInformationBody"),
  },
  uniField: {
    header: i18n.t("Explanations.uniFieldHeader"),
    body: i18n.t("Explanations.uniFieldBody"),
  },
};

// selectors
type ExplanationSliceRoot = {
  explanation: ReturnType<typeof explanation.reducer>;
};

// The explanation object for the currently selected key
// For changes in translation language to work, the caller of this function must
// use the "useTranslation" hook.
export const selectExplanation = (state: ExplanationSliceRoot) => {
  if (!(state.explanation.key in explanations))
    return {
      header: i18n.t("ErrorScreen.title"),
      body: i18n.t("ErrorScreen.body"),
    };
  return explanations[state.explanation.key];
};

// Whether or not the explanation modal should be open
export const selectOpenExplanation = (state: ExplanationSliceRoot) =>
  state.explanation.key !== "";
