import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

// de.md and en.md are appended according to language
const Gtcs: Record<string, string> = {
  musicroom: "/gtc_musicrooms",
  onlinePayment: "/gtc_online_payment",
  privacyPolicy: "/privacy",
};

export const gtcApi = createApi({
  reducerPath: "gtcApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  tagTypes: ["gtc"],
  endpoints: (builder) => ({
    getGtcDocument: builder.query<string, { gtc: string; lang: "en" | "de" }>({
      query: ({ gtc, lang }) => ({
        url: `${Gtcs[gtc]}_${lang}.md`,
        responseHandler: "text",
      }),
      providesTags: (res, err, { gtc }) => [{ type: "gtc", id: gtc }],
    }),
  }),
});

export const { useGetGtcDocumentQuery } = gtcApi;
