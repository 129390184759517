import { combineReducers } from "@reduxjs/toolkit";

import auth from "../features/auth/authSlice";
import { userApi } from "../features/user/userSlice";
import explanation from "../features/explanation/explanationSlice";
import { gtcApi } from "../features/gtc/gtcSlice";

/**
 * Construct and export the root reducer used in this redux application
 */

const rootReducer = combineReducers({
  auth,
  [userApi.reducerPath]: userApi.reducer,
  explanation,
  [gtcApi.reducerPath]: gtcApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
