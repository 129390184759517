import React from "react";
import { Button, Container } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const HelloPage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <section className="mb-5">
        <h1 className="mb-3">{t("HelloPage.title")}</h1>

        <p>{t("HelloPage.body")}</p>

        <Link to="/account"><Button>{t("HelloPage.toaccount")}</Button></Link>
      </section>
    </Container>
  );
};
