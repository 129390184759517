import jwt_decode from "jwt-decode"

import { AuthState } from "vseth-canine-ui";


/**
 * Extracts the infomration of an auth Token and creates an AuthState
 * @param tokens Keycloak tokens
 */
export const getAuthStateFromToken = (
    tokens: any = {
        idToken: "",
        refreshToken: "",
        token: ""
    }
): AuthState => {
    const decodedJwt =
        tokens && tokens.idToken ? (jwt_decode(tokens.idToken) as any) : {};
    const authState = {
        isAuthenticated: !!(tokens && tokens.token),
        tokens,
        userInfo: {
            resource_access: {},
            azp: "",
            exp: 0,
            prefered_username: "",
            family_name: "",
            given_name: "",
            name: "",
            ...decodedJwt
        }
    }

    return authState;
}
