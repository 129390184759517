import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDisableOISFieldsForUser,
  useGetUserQuery,
  User,
  useUpdateUserMutation,
} from "../features/user/userSlice";
import { LoadScreen } from "../components/LoadScreen";
import { ErrorScreen } from "../components/ErrorScreen";
import { EditBasicInfo } from "../components/EditAccountComponents/EditBasicInfo";
import { EditAddress } from "../components/EditAccountComponents/EditAddress";
import { EditBankAccount } from "../components/EditAccountComponents/EditBankAccount";
import { useTranslation } from "react-i18next";
import { diff } from "deep-diff";
import ExplanationModal from "../components/ExplanationModel";
import {
  hideExplanation,
  selectExplanation,
  selectOpenExplanation,
  showExplanation,
} from "../features/explanation/explanationSlice";
import { selectUserInfo } from "../features/auth/authSlice";

interface AEPProps { }

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

// helper method that generates the field mask paths that are needed to update
// a user object through the people api
function generateDiffPaths(initialUser: User, updatedUser: User): string[] {
  const differences = diff(initialUser, updatedUser);

  if (!differences) {
    return [];
  }

  return differences
    .map((change) => {
      if (!change.path) {
        return "";
      }

      const joinedPath = change.path.join(".");
      if (joinedPath.indexOf("birthdate") > -1) {
        return "birthdate";
      }

      if (joinedPath.indexOf("bankAccount") > -1) {
        return "bank_account";
      }

      return camelToSnakeCase(change.path.join("."));
    })
    .filter((path) => path !== "");
}

// AccountEditPage renders forms to edit account information
// It shows different forms depending on the parameter passed in the URL
export const AccountEditPage = (props: AEPProps) => {
  const { section } = useParams<{ section: string }>();
  const sub = (useSelector(selectUserInfo) as unknown as {sub: string}).sub;

  const {
    data: user,
    isLoading: userIsLoading,
    isFetching: userFetching,
    error: userError,
  } = useGetUserQuery(
    {name: `users/${sub}`}, {}
  );
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const disableOISFields = useSelector(selectDisableOISFieldsForUser);
  const explanation = useSelector(selectExplanation);
  const openExplanation = useSelector(selectOpenExplanation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Content
  if (userIsLoading || userFetching) return <LoadScreen />;
  if (userError || !user) return <ErrorScreen />;

  const updateUserAndNavigate = ((user: User, newInfo: User) => {
    const paths = generateDiffPaths(user, newInfo);
    updateUser({paths: paths, user: newInfo})
      .unwrap().then(() =>
        navigate("/account"));
  });


  switch (section) {
    case "basic":
      return (
        <Container>
          <h1>{t("AccountEditPage.editBasicInfo")}</h1>
          <EditBasicInfo
            user={user}
            disableOISFields={disableOISFields}
            onSubmit={(newInfo) => {
              updateUserAndNavigate(user, newInfo);
            }}
            setExplanation={(key) => dispatch(showExplanation(key))}
            isUpdating={isUpdating}
          />
          <ExplanationModal
            isOpen={openExplanation}
            explanation={explanation}
            onClose={() => dispatch(hideExplanation())}
          />
        </Container>
      );
    case "address":
      return (
        <Container>
          <h1>{t("AccountEditPage.editAddress")}</h1>
          <EditAddress
            postalAddress={user.postalAddress}
            disableOISFields={disableOISFields}
            onSubmit={(newAddress) => {
              const updatedUser: User = {
                ...user,
                postalAddress: newAddress,
              };
              updateUserAndNavigate(user, updatedUser);

            }}
            setExplanation={(key) => dispatch(showExplanation(key))}
            isUpdating={isUpdating}
          />
          <ExplanationModal
            isOpen={openExplanation}
            explanation={explanation}
            onClose={() => dispatch(hideExplanation())}
          />
        </Container>
      );
    case "bankaccount":
      return (
        <Container>
          <h1>{t("AccountEditPage.editBankingInfo")}</h1>
          <EditBankAccount
            bankAccount={user.bankAccount}
            onSubmit={(newBankAccount) => {
              const updatedUser: User = {
                ...user,
                bankAccount: newBankAccount,
              };
              updateUserAndNavigate(user, updatedUser);
            }}
            setExplanation={(key) => dispatch(showExplanation(key))}
            isUpdating={isUpdating}
          />
          <ExplanationModal
            isOpen={openExplanation}
            explanation={explanation}
            onClose={() => dispatch(hideExplanation())}
          />
        </Container>
      );
    default:
      return <ErrorScreen />;
  }
};
