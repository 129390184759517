/* eslint-disable */ 
/**
 * @fileoverview gRPC-Web generated client stub for sip.people
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: sip/people/people.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../../google/type/date_pb.js')
const proto = {};
proto.sip = {};
proto.sip.people = require('./people_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sip.people.PeopleClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sip.people.PeoplePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.sip.people.ListAuthorizationRolesResponse>}
 */
const methodDescriptor_People_ListAuthorizationRoles = new grpc.web.MethodDescriptor(
  '/sip.people.People/ListAuthorizationRoles',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.sip.people.ListAuthorizationRolesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.ListAuthorizationRolesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.ListAuthorizationRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.ListAuthorizationRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.listAuthorizationRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/ListAuthorizationRoles',
      request,
      metadata || {},
      methodDescriptor_People_ListAuthorizationRoles,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.ListAuthorizationRolesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.listAuthorizationRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/ListAuthorizationRoles',
      request,
      metadata || {},
      methodDescriptor_People_ListAuthorizationRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.ListGroupsRequest,
 *   !proto.sip.people.ListGroupsResponse>}
 */
const methodDescriptor_People_ListGroups = new grpc.web.MethodDescriptor(
  '/sip.people.People/ListGroups',
  grpc.web.MethodType.UNARY,
  proto.sip.people.ListGroupsRequest,
  proto.sip.people.ListGroupsResponse,
  /**
   * @param {!proto.sip.people.ListGroupsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.ListGroupsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.people.ListGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.ListGroupsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.ListGroupsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.listGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/ListGroups',
      request,
      metadata || {},
      methodDescriptor_People_ListGroups,
      callback);
};


/**
 * @param {!proto.sip.people.ListGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.ListGroupsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.listGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/ListGroups',
      request,
      metadata || {},
      methodDescriptor_People_ListGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.GetGroupRequest,
 *   !proto.sip.people.Group>}
 */
const methodDescriptor_People_GetGroup = new grpc.web.MethodDescriptor(
  '/sip.people.People/GetGroup',
  grpc.web.MethodType.UNARY,
  proto.sip.people.GetGroupRequest,
  proto.sip.people.Group,
  /**
   * @param {!proto.sip.people.GetGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.Group.deserializeBinary
);


/**
 * @param {!proto.sip.people.GetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.Group)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.Group>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.getGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/GetGroup',
      request,
      metadata || {},
      methodDescriptor_People_GetGroup,
      callback);
};


/**
 * @param {!proto.sip.people.GetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.Group>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.getGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/GetGroup',
      request,
      metadata || {},
      methodDescriptor_People_GetGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.BatchGetGroupRequest,
 *   !proto.sip.people.BatchGetGroupResponse>}
 */
const methodDescriptor_People_BatchGetGroup = new grpc.web.MethodDescriptor(
  '/sip.people.People/BatchGetGroup',
  grpc.web.MethodType.UNARY,
  proto.sip.people.BatchGetGroupRequest,
  proto.sip.people.BatchGetGroupResponse,
  /**
   * @param {!proto.sip.people.BatchGetGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.BatchGetGroupResponse.deserializeBinary
);


/**
 * @param {!proto.sip.people.BatchGetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.BatchGetGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.BatchGetGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.batchGetGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/BatchGetGroup',
      request,
      metadata || {},
      methodDescriptor_People_BatchGetGroup,
      callback);
};


/**
 * @param {!proto.sip.people.BatchGetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.BatchGetGroupResponse>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.batchGetGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/BatchGetGroup',
      request,
      metadata || {},
      methodDescriptor_People_BatchGetGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.CreateGroupRequest,
 *   !proto.sip.people.Group>}
 */
const methodDescriptor_People_CreateGroup = new grpc.web.MethodDescriptor(
  '/sip.people.People/CreateGroup',
  grpc.web.MethodType.UNARY,
  proto.sip.people.CreateGroupRequest,
  proto.sip.people.Group,
  /**
   * @param {!proto.sip.people.CreateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.Group.deserializeBinary
);


/**
 * @param {!proto.sip.people.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.Group)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.Group>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.createGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_People_CreateGroup,
      callback);
};


/**
 * @param {!proto.sip.people.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.Group>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.createGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_People_CreateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.UpdateGroupRequest,
 *   !proto.sip.people.Group>}
 */
const methodDescriptor_People_UpdateGroup = new grpc.web.MethodDescriptor(
  '/sip.people.People/UpdateGroup',
  grpc.web.MethodType.UNARY,
  proto.sip.people.UpdateGroupRequest,
  proto.sip.people.Group,
  /**
   * @param {!proto.sip.people.UpdateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.Group.deserializeBinary
);


/**
 * @param {!proto.sip.people.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.Group)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.Group>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.updateGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_People_UpdateGroup,
      callback);
};


/**
 * @param {!proto.sip.people.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.Group>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.updateGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_People_UpdateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.DeleteGroupRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_People_DeleteGroup = new grpc.web.MethodDescriptor(
  '/sip.people.People/DeleteGroup',
  grpc.web.MethodType.UNARY,
  proto.sip.people.DeleteGroupRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.sip.people.DeleteGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.sip.people.DeleteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.deleteGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_People_DeleteGroup,
      callback);
};


/**
 * @param {!proto.sip.people.DeleteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.deleteGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_People_DeleteGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.ChangeGroupMembersRequest,
 *   !proto.sip.people.Group>}
 */
const methodDescriptor_People_ChangeGroupMembers = new grpc.web.MethodDescriptor(
  '/sip.people.People/ChangeGroupMembers',
  grpc.web.MethodType.UNARY,
  proto.sip.people.ChangeGroupMembersRequest,
  proto.sip.people.Group,
  /**
   * @param {!proto.sip.people.ChangeGroupMembersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.Group.deserializeBinary
);


/**
 * @param {!proto.sip.people.ChangeGroupMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.Group)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.Group>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.changeGroupMembers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/ChangeGroupMembers',
      request,
      metadata || {},
      methodDescriptor_People_ChangeGroupMembers,
      callback);
};


/**
 * @param {!proto.sip.people.ChangeGroupMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.Group>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.changeGroupMembers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/ChangeGroupMembers',
      request,
      metadata || {},
      methodDescriptor_People_ChangeGroupMembers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.ListUsersRequest,
 *   !proto.sip.people.ListUsersResponse>}
 */
const methodDescriptor_People_ListUsers = new grpc.web.MethodDescriptor(
  '/sip.people.People/ListUsers',
  grpc.web.MethodType.UNARY,
  proto.sip.people.ListUsersRequest,
  proto.sip.people.ListUsersResponse,
  /**
   * @param {!proto.sip.people.ListUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.ListUsersResponse.deserializeBinary
);


/**
 * @param {!proto.sip.people.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.ListUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.ListUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.listUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/ListUsers',
      request,
      metadata || {},
      methodDescriptor_People_ListUsers,
      callback);
};


/**
 * @param {!proto.sip.people.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.ListUsersResponse>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.listUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/ListUsers',
      request,
      metadata || {},
      methodDescriptor_People_ListUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.GetUserRequest,
 *   !proto.sip.people.User>}
 */
const methodDescriptor_People_GetUser = new grpc.web.MethodDescriptor(
  '/sip.people.People/GetUser',
  grpc.web.MethodType.UNARY,
  proto.sip.people.GetUserRequest,
  proto.sip.people.User,
  /**
   * @param {!proto.sip.people.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.User.deserializeBinary
);


/**
 * @param {!proto.sip.people.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/GetUser',
      request,
      metadata || {},
      methodDescriptor_People_GetUser,
      callback);
};


/**
 * @param {!proto.sip.people.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.User>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/GetUser',
      request,
      metadata || {},
      methodDescriptor_People_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.BatchGetUserRequest,
 *   !proto.sip.people.BatchGetUserResponse>}
 */
const methodDescriptor_People_BatchGetUser = new grpc.web.MethodDescriptor(
  '/sip.people.People/BatchGetUser',
  grpc.web.MethodType.UNARY,
  proto.sip.people.BatchGetUserRequest,
  proto.sip.people.BatchGetUserResponse,
  /**
   * @param {!proto.sip.people.BatchGetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.BatchGetUserResponse.deserializeBinary
);


/**
 * @param {!proto.sip.people.BatchGetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.BatchGetUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.BatchGetUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.batchGetUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/BatchGetUser',
      request,
      metadata || {},
      methodDescriptor_People_BatchGetUser,
      callback);
};


/**
 * @param {!proto.sip.people.BatchGetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.BatchGetUserResponse>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.batchGetUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/BatchGetUser',
      request,
      metadata || {},
      methodDescriptor_People_BatchGetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.CreateUserRequest,
 *   !proto.sip.people.User>}
 */
const methodDescriptor_People_CreateUser = new grpc.web.MethodDescriptor(
  '/sip.people.People/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.sip.people.CreateUserRequest,
  proto.sip.people.User,
  /**
   * @param {!proto.sip.people.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.User.deserializeBinary
);


/**
 * @param {!proto.sip.people.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/CreateUser',
      request,
      metadata || {},
      methodDescriptor_People_CreateUser,
      callback);
};


/**
 * @param {!proto.sip.people.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.User>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/CreateUser',
      request,
      metadata || {},
      methodDescriptor_People_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.UpdateUserRequest,
 *   !proto.sip.people.User>}
 */
const methodDescriptor_People_UpdateUser = new grpc.web.MethodDescriptor(
  '/sip.people.People/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.sip.people.UpdateUserRequest,
  proto.sip.people.User,
  /**
   * @param {!proto.sip.people.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.User.deserializeBinary
);


/**
 * @param {!proto.sip.people.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_People_UpdateUser,
      callback);
};


/**
 * @param {!proto.sip.people.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.User>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_People_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.DeleteUserRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_People_DeleteUser = new grpc.web.MethodDescriptor(
  '/sip.people.People/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.sip.people.DeleteUserRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.sip.people.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.sip.people.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_People_DeleteUser,
      callback);
};


/**
 * @param {!proto.sip.people.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_People_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.people.ListAuditLogEntriesRequest,
 *   !proto.sip.people.ListAuditLogEntriesResponse>}
 */
const methodDescriptor_People_ListAuditLogEntries = new grpc.web.MethodDescriptor(
  '/sip.people.People/ListAuditLogEntries',
  grpc.web.MethodType.UNARY,
  proto.sip.people.ListAuditLogEntriesRequest,
  proto.sip.people.ListAuditLogEntriesResponse,
  /**
   * @param {!proto.sip.people.ListAuditLogEntriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.people.ListAuditLogEntriesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.people.ListAuditLogEntriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.people.ListAuditLogEntriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.people.ListAuditLogEntriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.listAuditLogEntries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/ListAuditLogEntries',
      request,
      metadata || {},
      methodDescriptor_People_ListAuditLogEntries,
      callback);
};


/**
 * @param {!proto.sip.people.ListAuditLogEntriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.people.ListAuditLogEntriesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.listAuditLogEntries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/ListAuditLogEntries',
      request,
      metadata || {},
      methodDescriptor_People_ListAuditLogEntries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_People_TriggerGroupSyncToLdap = new grpc.web.MethodDescriptor(
  '/sip.people.People/TriggerGroupSyncToLdap',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.people.PeopleClient.prototype.triggerGroupSyncToLdap =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.people.People/TriggerGroupSyncToLdap',
      request,
      metadata || {},
      methodDescriptor_People_TriggerGroupSyncToLdap,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.sip.people.PeoplePromiseClient.prototype.triggerGroupSyncToLdap =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.people.People/TriggerGroupSyncToLdap',
      request,
      metadata || {},
      methodDescriptor_People_TriggerGroupSyncToLdap);
};


module.exports = proto.sip.people;

