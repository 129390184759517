import React from "react";
import { useFormikContext } from "formik";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface Props {
  name: string;
  selected: Date;
  disabled?: boolean;
  className?: string;
}

const DatePickerField = ({ ...props }: Props) => {
  const formik = useFormikContext();
  return (
    <div style={{ width: "100%" }}>
      <DatePicker
        {...props}
        dateFormat={"dd.MM.yyyy"}
        showMonthDropdown
        showYearDropdown
        onChange={(value) => formik.setFieldValue(props.name, value)}
        onBlur={() => formik.setFieldTouched(props.name, true, true)}
      />
    </div>
  );
};

export default DatePickerField;
