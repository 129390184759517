import { Icon, ICONS } from "vseth-canine-ui";
import React, { useState } from "react";
import { Group } from "@mantine/core";

interface Props {
  children: React.ReactNode;
  hide?: boolean;
  onClick?: () => void;
  iconSize?: string;
}

/**
 * Table data cell with a clickable ? icon.
 * Icon can be hidden by setting the hide prop to true.
 * @param props
 */
const TdWithExplanationIcon: React.FC<Props> = (props) => {
  const [opacity, setOpacity] = useState(0.6);

  const increaseOpacity = () => setOpacity(1);
  const decreaseOpacity = () => setOpacity(0.6);

  return (
    <td>
      <Group position="apart">
        {props.children}
        {!props.hide && (
          <div
            onClick={(_) => props.onClick && props.onClick()}
            onMouseEnter={(_) => increaseOpacity()}
            onMouseLeave={(_) => decreaseOpacity()}
            style={{ opacity: opacity, flex: "1 1 auto", marginRight: 0 }}
          >
            <Icon icon={ICONS.HELP} size={props.iconSize || "1em"} style={{ float: "right" }} />
          </div>
        )}
      </Group>
    </td>
  );
};

export default TdWithExplanationIcon;
