import React from "react";
import {
  Modal,

} from "@mantine/core";

export interface Explanation {
  header: string;
  body: string;
}

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  explanation: Explanation;
}

/**
 * A modal to display explanatory information
 * @param props
 */
export const ExplanationModal: React.FC<Props> = (props) => {

  return (
    <Modal opened={props.isOpen} onClose={() => props.onClose && props.onClose()} title={props.explanation.header} centered>
      {props.explanation.body}
    </Modal>
  );
};

export default ExplanationModal;
