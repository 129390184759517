import React from "react";
import {Button, ButtonProps} from "@mantine/core";
import {Icon, ICONS} from "vseth-canine-ui"


interface GroupProps extends ButtonProps {
    input: string;
}

export const Group: React.FC<GroupProps> = ({ input, ...buttonProps }) => {
    return (
        <Button
            component="a"
            target="_blank"
            href={`https://groups.vseth.ethz.ch/${ input}`}
            variant="outline"
            compact
            rel="noopener noreferrer"
            radius="xl"
            m="0.4em"
            leftIcon={<Icon icon={ICONS.LINK} size={"0.7rem"} />}>

            {input.startsWith("groups/") ? input.slice(7) : input}
        </Button>

    );
};
