import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { BankAccount } from "../../features/user/userSlice";
import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import LabelWithExplanationIcon from "../LabelWithExplanationIcon";
import { regexConstants } from "../../util/regexConstants";
import * as iban from "ibantools";

interface EBIProps {
  bankAccount?: BankAccount;
  onSubmit: (ba: BankAccount) => void;
  setExplanation: (key: string) => void;
  isUpdating?: boolean;
}

// EditBankAccount is a form to edit (or add) a user's bank account information
export const EditBankAccount: React.FC<EBIProps> = ({
  bankAccount,
  onSubmit,
  isUpdating = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={
        bankAccount
          ? {
              accountHolderName: bankAccount.accountHolderName,
              bankName: bankAccount.bankName,
              iban: bankAccount.iban,
              bic: bankAccount.bic,
              afbStreetAddress: bankAccount.bankingAddress
                ? bankAccount.bankingAddress.streetAddress
                : "",
              afbLocality: bankAccount.bankingAddress
                ? bankAccount.bankingAddress.locality
                : "",
              afbPostalCode: bankAccount.bankingAddress
                ? bankAccount.bankingAddress.postalCode
                : "",
              afbCountry: bankAccount.bankingAddress
                ? bankAccount.bankingAddress.country
                  ? bankAccount.bankingAddress.country
                  : "CH"
                : "CH",
            }
          : {
              accountHolderName: "",
              bankName: "",
              iban: "",
              bic: "",
              afbStreetAddress: "",
              afbLocality: "",
              afbPostalCode: "",
              afbCountry: "CH",
            }
      }
      validationSchema={Yup.object({
        accountHolderName: Yup.string()
          .trim()
          .matches(regexConstants.name, t("formValidation.nameInvalid"))
          .required(t("formValidation.required")),
        bankName: Yup.string()
          .trim()
          .matches(regexConstants.name, t("formValidation.nameInvalid"))
          .required(t("formValidation.required")),
        iban: Yup.string()
          .trim()
          .test("is-iban", t("formValidation.ibanInvalid"), (value) => {
            if (value === null || value === undefined) {
              return false;
            }
            // check that we have a valid iban (global)
            const validIban = iban.isValidIBAN(value);
            if (!validIban) {
              return false;
            }
            // check that the country code is a sepa country
            return iban.isSEPACountry(value.substr(0, 2));
          })
          .required(t("formValidation.required")),
        bic: Yup.string()
          .trim()
          .test("is-iban", t("formValidation.bicInvalid"), (value) => {
            if (value === null || value === undefined || value.length < 11) {
              return false;
            }
            return iban.isValidBIC(value);
          })
          .required(t("formValidation.required")),
        afbStreetAddress: Yup.string()
          .trim()
          .matches(
            regexConstants.streetAddress,
            t("formValidation.streetAddressInvalid")
          )
          .required(t("formValidation.required")),
        afbLocality: Yup.string()
          .trim()
          .matches(regexConstants.locality, t("formValidation.localityInvalid"))
          .required(t("formValidation.required")),
        afbPostalCode: Yup.string()
          .trim()
          .min(4, t("formValidation.tooShort"))
          .matches(
            regexConstants.postalCode,
            t("formValidation.postalCodeInvalid")
          )
          .required(t("formValidation.required")),
        afbCountry: Yup.string().trim().required(t("formValidation.required")),
      })}
      onSubmit={(values, formikHelpers) => {
        const updatedBankingInfo: BankAccount = {
          accountHolderName: values.accountHolderName,
          bankName: values.bankName,
          iban: values.iban,
          bic: values.bic,
          bankingAddress: {
            streetAddress: values.afbStreetAddress,
            locality: values.afbLocality,
            postalCode: values.afbPostalCode,
            country: values.afbCountry,
            formatted: "",
          },
        };
        onSubmit(updatedBankingInfo);
      }}
    >
      {(formik) => (
        <div className="col-lg-12">
          <Form onSubmit={formik.handleSubmit}>
            <section className="mb-5">
              <h2>{t("EditBankAccount.bankAccountInformation")}</h2>
              <div className="form=group">
                <LabelWithExplanationIcon htmlFor="accountHolderName" hide>
                  {t("EditBankAccount.accountHolderName")}
                </LabelWithExplanationIcon>
                <Field
                  name="accountHolderName"
                  type="text"
                  className={`form-control ${
                    formik.touched.accountHolderName &&
                    formik.errors.accountHolderName
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  component="span"
                  name="accountHolderName"
                  className="text-danger"
                />
              </div>
              <div className="form=group">
                <LabelWithExplanationIcon htmlFor="bankName" hide>
                  {t("EditBankAccount.bankName")}
                </LabelWithExplanationIcon>
                <Field
                  name="bankName"
                  type="text"
                  className={`form-control ${
                    formik.touched.bankName && formik.errors.bankName
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  component="span"
                  name="bankName"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <LabelWithExplanationIcon htmlFor="iban" hide>
                  {t("EditBankAccount.iban")}
                </LabelWithExplanationIcon>
                <Field
                  name="iban"
                  type="text"
                  className={`form-control ${
                    formik.touched.iban && formik.errors.iban
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  component="span"
                  name="iban"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <LabelWithExplanationIcon htmlFor="bic" hide>
                  {t("EditBankAccount.bic")}
                </LabelWithExplanationIcon>
                <Field
                  name="bic"
                  type="text"
                  className={`form-control ${
                    formik.touched.bic && formik.errors.bic ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="span"
                  name="bic"
                  className="text-danger"
                />
              </div>
            </section>
            <section className="mb-5">
              <h2>{t("EditBankAccount.bankingAddress")}</h2>
              <div className="form-group">
                <LabelWithExplanationIcon htmlFor="afbStreetAddress" hide>
                  {t("EditBankAccount.streetAddress")}
                </LabelWithExplanationIcon>
                <Field
                  name="afbStreetAddress"
                  type="text"
                  className={`form-control ${
                    formik.touched.afbStreetAddress &&
                    formik.errors.afbStreetAddress
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  component="span"
                  name="afbStreetAddress"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="afbLocality">
                  {t("EditBankAccount.locality")}
                </label>
                <Field
                  name="afbLocality"
                  type="text"
                  className={`form-control ${
                    formik.touched.afbLocality && formik.errors.afbLocality
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  component="span"
                  name="afbLocality"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <LabelWithExplanationIcon htmlFor="afbPostalCode" hide>
                  {t("EditBankAccount.postalCode")}
                </LabelWithExplanationIcon>
                <Field
                  name="afbPostalCode"
                  type="text"
                  className={`form-control ${
                    formik.touched.afbPostalCode && formik.errors.afbPostalCode
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  component="span"
                  name="afbPostalCode"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <LabelWithExplanationIcon htmlFor="afbCountry" hide>
                  {t("EditBankAccount.country")}
                </LabelWithExplanationIcon>
                <Field
                  as="select"
                  name="afbCountry"
                  className={`form-control ${
                    formik.touched.afbCountry && formik.errors.afbCountry
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="AD">Andorra</option>
                  <option value="AT">Austria</option>
                  <option value="BE">Belgium</option>
                  <option value="HR">Croatia</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="EE">Estonia</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IE">Ireland</option>
                  <option value="IT">Italy</option>
                  <option value="LV">Latvia</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MT">Malta</option>
                  <option value="MC">Monaco</option>
                  <option value="NL">Netherlands</option>
                  <option value="NO">Norway</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="RO">Romania</option>
                  <option value="SM">San Marino</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="ES">Spain</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="GB">United Kingdom</option>
                </Field>
                <ErrorMessage
                  component="span"
                  name="afbCountry"
                  className="text-danger"
                />
              </div>
            </section>
            <div className="d-flex justify-content-end">
              <Button
                color="secondary"
                className="m-2"
                onClick={() => navigate("/account")}
              >
                {t("buttons.cancel")}
              </Button>
              <Button
                color="primary"
                type="submit"
                className="m-2"
                disabled={!formik.dirty || !formik.isValid}
                loading={isUpdating}
              >
                {t("buttons.save")}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
