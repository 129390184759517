import React, { ReactNode } from "react";
import "./App.css";
import { Outlet, Route, RouterProvider, createRoutesFromElements, createBrowserRouter, Navigate, Link } from "react-router-dom";
import "@vseth/vseth-theme/dist/vseth-bootstrap-theme.css";
import {
  makeVsethTheme,
  VSETHExternalApp,
  VSETHThemeProvider,
  ProtectedRoute,
  useConfig,
} from "vseth-canine-ui";


import { Navbar } from "../components/Navbar";
import { CustomAuthProvider } from "../components/CustomAuthProvider";
import { AccountPage } from "../pages/AccountPage";
import { HelloPage } from "../pages/HelloPage";
import { AccountEditPage } from "../pages/AccountEditPage";
import { ImprintPage } from "../pages/ImprintPage";
import { GtcDisplayPage } from "../pages/GtcDisplayPage";
import { useTranslation } from "react-i18next";
import { Anchor } from "@mantine/core";
import { ErrorScreen } from "../components/ErrorScreen";


/**
 * `App` is the main component of the application it set up the main structural components
 * such as the navigation bar and the Routes.
 */
const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  let { data } = useConfig(
    "https://static.vseth.ethz.ch/assets/vseth-0000-vseth/config.json"
  );


  const theme = makeVsethTheme();
  theme.globalStyles = (theme) => ({
    'html': {
      fontSize: "1.15rem"
    },
    ".PhoneInputInput": {
      border: "none",
    },
  });




  const rootElem = (
    <CustomAuthProvider>
      <VSETHThemeProvider theme={theme}>
        <VSETHExternalApp
          selectedLanguage={i18n.language}
          onLanguageSelect={(lang: any) => i18n.changeLanguage(lang)}
          languages={data?.languages}
          title={"VSETH Account"}
          appNav={Navbar()}
          organizationNav={data.externalNav}
          activeHref={""}
          privacyPolicy={data?.privacy}
          disclaimer={data?.copyright}
          socialMedia={data?.socialMedia}
          logo={data?.logo}
          showLoginButton={true}
          makeWrapper={(url: string | undefined, child: ReactNode) => (
            ((url || url?.startsWith("/")) ?
            <Link to={url ? url : ""} style={{ textDecoration: "none", color: "inherit" }} >
              {child}
            </Link>
            : <Anchor href={url}>{child}</Anchor>)
          )} 
        >
          <Outlet />
        </VSETHExternalApp>
      </VSETHThemeProvider>
    </CustomAuthProvider>
  );


  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        //isAuthorized={allowedToListGroups}
        //notAuthorizedComponent={NotAuthorizedScreen}
        path="/"
        element={rootElem}
        errorElement={<ErrorScreen />}
      >
        <Route
          index
          element={<HelloPage />}
        />
        <Route
          path="privacy"
          element={<Navigate to="/legal/privacy" replace />}
        />
        <Route
          path="legal"
          element={<GtcDisplayPage />}
        />
        <Route
          path="/legal/:gtc"
          element={<GtcDisplayPage />}
        />
        <Route
          path="impressum"
          element={<ImprintPage />}
        />
        <Route
          path="impressum/:org"
          element={<ImprintPage />}
        />
        <Route
          path="account"
          element={<ProtectedRoute><AccountPage /></ProtectedRoute>}
        />
        <Route
          path="account/edit/:section"
          element={<ProtectedRoute><AccountEditPage /></ProtectedRoute>}
        />
      </Route>
    )
  );



  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>

  );
};

export default App;
