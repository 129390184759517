export const i18n_german = {
  translation: {
    lang: "de",
    example: {
      title: "Translations are important!",
      introduction:
        "A lot of members of ETH Zurich do not speak german, so it is very important for the IT to tools that can be easily translated into multiple languages. This template showcases how this can be achieved.",
      buttonText: "Sprache ändern",
    },
    buttons: {
      edit: "Bearbeiten",
      save: "Änderungen speichern",
      cancel: "Abbrechen",
      back: "Zurück",
      close: "Schliessen",
      reload: "Neu laden",
      addPostalAddress: "Postadresse hinzufügen",
      addBankAccount: "Bankkonto hinzufügen",
      addEmploymentInformation: "Informationen zur Erwerbstätigkeit hinzufügen",
      display: "Anzeigen",
    },
    formValidation: {
      required: "Erforderlich",
      tooShort: "Zu kurz",
      nameInvalid: "Ungültiger Name",
      emailInvalid: "Ungültige Email",
      phoneNumberInvalid: "Ungültige Telefonnummer",
      streetAddressInvalid: "Ungültige Adresse",
      localityInvalid: "Ungültige Gemeinde",
      postalCodeInvalid: "Ungütlige Postleitzahl",
      ibanInvalid: "Ungültige IBAN: Format z.B. CHXXXXXXXXXXXXXXXXXXX (nur SEPA Länder werden unterstützt)",
      bicInvalid: "Ungültige BIC",
      ahvNumberInvalid:
        "Ungültige AHV-Nummer - muss der Form '756.XXXX.XXXX.XX' sein",
      zemisInvalid: "Ungültige ZEMIS-Nummer - muss der Form 'XXXX.XXXX-X' sein",
    },
    App: {
      name: "VSETH Account"
    },
    Navbar: {
      home: "Home",
      account: "Account",
      legal: "Rechtliches",
    },
    Footer: {
      impressum: "Impressum",
      privacyPolicy: "Datenschutz",
    },
    ErrorScreen: {
      title: "Ein Fehler ist aufgetreten.",
      body: "Versuchen Sie, die Seite neu zu laden, und wenden Sie sich an den Administrator, wenn der Fehler weiterhin besteht.",
    },
    LoadScreen: {
      loadingData: "Die Daten werden geladen",
    },
    HelloPage: {
      title: "Willkommen zu deinem VSETH Account",
      body: "Dieser Service bietet Ihnen einen zentralen Ort, an dem Sie alle Ihre VSETH-Daten verwalten können.",
      toaccount: "Zu deinem Account",
    },
    AccountPage: {
      accountInfo: "Account Informationen",
      basicInfo: "Allgemeine Informationen",
      username: "Benutzername",
      firstName: "Vorname",
      lastName: "Nachname",
      telephone: "Telefonnummer",
      email: "Email",
      birthdate: "Geburtsdatum",
      swissEduIdentifier: "Swiss Edu Identifier",
      swissEduPersonAffiliation: "Swiss Edu Person Zugehörigkeit",
      studentNumber: "Matrikelnummer",
      studySemester: "Semester",
      ethFachId: "ETH Fach",
      groupMembership: "Gruppenmitgliedschaften",
      address: "Adresse",
      street: "Strasse",
      locality: "Stadt",
      postalCode: "Postleitzahl",
      country: "Land",
      bankAccount: "Bankkonto-Informationen",
      accountHolderName: "Konto InhaberIn",
      iban: "IBAN",
      bic: "BIC",
      bankName: "Name der Bank",
      bankingAddress: "Adresse (bei Bank gespeichert)",
      noBankingAddress: "Keine Angabe",
      employmentInformation: "Informationen zur Erwerbstätigkeit",
      nationality: "Staatsangehörigkeit",
      ahvNumber: "AHV-Nummer",
      zemisNumber: "ZEMIS-Nummer",
      numberOfKids: "Anzahl Kinder",
      numberOfKidsUnspecified: "Keine Angabe",
      vsethMembership: {
        title: "VSETH Mitgliedschaft",
        exitDate: "VSETH Mitgliedschaft Austrittsdatum",
        noExitDate: "Noch nicht ausgetreten",
        member: "Mitglied",
        notMember: "Kein Mitglied",
        unspecified: "Keine Angabe",
      },
      vsethMembershipType: {
        title: "Typ der VSETH Mitgliedschaft",
        bachelorOrMaster: "Normale Mitgliedschaft",
        phd: "Mitgliedschaft PhD",
        vsethEmployees: "Mitgliedschaft Mitarbeiter:in",
        ethApprentice: "ETH Apprentice Membership",
        mobilityStudent: "Mobility Student Membership",
        inContinuingEducation: "Continuing Education Student Membership",
        auditor: "Mitgliedschaft Hörer",
        unspecified: "Keine Angabe",
      },
      civilStatus: {
        title: "Zivilstand",
        divorced: "geschieden",
        married: "verheiratet",
        separated: "getrennt",
        widowed: "verwitwet",
        unmarried: "ledig",
        registeredPartnership: "eingetragene Partnerschaft",
        dissolvedPartnership: "aufgelöste Partnerschaft",
        unspecified: "Keine Angabe",
      },
      residencePermit: {
        title: "Aufenhaltstitel",
        bPermit: "Ausweis B",
        cPermit: "Ausweis C",
        unspecified: "Keine Angabe",
      },
      confession: {
        none: "Keine",
        catholic: "römisch-katholisch",
        christCatholic: "christ-katholisch",
        protestant: "evangelisch",
        unspecified: "Keine Angabe",
      },
    },
    AccountEditPage: {
      editBasicInfo: "Allgemeine Informationen bearbeiten",
      editAddress: "Postadresse bearbeiten",
      editBankingInfo: "Bankinformationen bearbeiten",
      editEmploymentInformation:
        "Informationen zur Erwerbstätigkeit bearbeiten",
    },
    EditBasicInfo: {
      firstName: "Vorname",
      lastName: "Nachname",
      birthdate: "Geburtsdatum",
      email: "Email",
      telephone: "Telefonnummer",
    },
    EditAddress: {
      streetAddress: "Strasse inkl. Hausnummer",
      locality: "Stadt",
      postalCode: "Postleitzahl",
      country: "Land",
    },
    EditBankAccount: {
      bankAccountInformation: "Bankkonto-Informationen",
      accountHolderName: "Konto InhaberIn",
      bankName: "Name der Bank",
      iban: "IBAN",
      bic: "BIC",
      bankingAddress: "Adresse (bei Bank gespeichert)",
      streetAddress: "Strasse inkl. Hausnummer",
      locality: "Stadt",
      postalCode: "Postleitzahl",
      country: "Land",
    },
    EditEmploymentInformation: {
      nationality: "Staatsangehörigkeit",
      ahvNumber: "AHV-Nummer",
      zemisNumber: "ZEMIS-Nummer",
      numberOfKids: "Anzahl Kinder",
      civilStatus: {
        title: "Zivilstand",
        divorced: "geschieden",
        married: "verheiratet",
        seperated: "getrennt",
        widowed: "verwitwet",
        unmarried: "ledig",
        registeredPartnership: "eingetragene Partnerschaft",
        dissolvedPartnership: "aufgelöste Partnerschaft",
        unspecified: "Keine Angabe",
      },
      residencePermit: {
        title: "Aufenhaltstitel",
        bPermit: "Ausweis B",
        cPermit: "Ausweis C",
        unspecified: "Keine Angabe",
      },
      confession: {
        none: "Keine",
        catholic: "römisch-katholisch",
        christCatholic: "christ-katholisch",
        protestant: "evangelisch",
        unspecified: "Keine Angabe",
      },
    },
    Explanations: {
      oisHeader: "Externe Informationen",
      oisBody:
        "Diese Informationen werden nicht direkt vom VSETH verwaltet. " +
        "Wenn Sie diese ändern möchten, tun Sie dies bitte an Ihrer Hochschule oder, wenn Sie nicht studieren, in Ihrem SWITCH-Konto.",
      vsethInformationHeader: "VSETH Information",
      vsethInformationBody:
        "Diese Informationen werden vom VSETH verwaltet. " +
        'Wenn Sie diese ändern möchten, können Sie dies tun, indem Sie auf den Button "Bearbeiten" unten klicken.',
      vsethFixedInformationHeader: "VSETH Information",
      vsethFixedInformationBody: `Diese Informationen werden vom VSETH verwaltet und können nicht geändert werden.`,
      uniFieldHeader: "University Information",
      uniFieldBody:
        "Diese Informationen werden von Ihrer Universität bereitgestellt und können nicht geändert werden.",
    },
    Imprint: {
      title: "Impressum",
      intro: `Dieses Impressum stellt die gesetzlich vorgeschriebene
                            Herkunftsangabe aller unserer Websites dar, um die für die
                            für den Inhalt Verantwortlichen, im Folgenden die
                            Veröffentlichenden, kenntlich zu machen.`,
      contactAddress: "Kontakt-Adresse",
      legalRepresentatives: "Vertretungsberechtigte Personen",
      disclaimer: "Haftungsausschluss",
      disclaimerText: `Die Veröffentlichenden übernehmen keinerlei Gewähr
                            hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit,
                            Aktualität, Zuverlässigkeit und Vollständigkeit der
                            Informationen. Haftungsansprüche gegen die
                            Veröffentlichenden wegen Schäden materieller oder
                            immaterieller Art, welche aus dem Zugriff oder der Nutzung
                            bzw. Nichtnutzung der veröffentlichten Informationen, durch
                            Missbrauch der Verbindung oder durch technische Störungen
                            entstanden sind, werden ausgeschlossen. Alle Angebote sind
                            unverbindlich. Die Veröffentlichenden behalten es sich
                            ausdrücklich vor, Teile der Seiten oder das gesamte Angebot
                            ohne besondere Ankündigung zu verändern, zu ergänzen, zu
                            löschen oder die Veröffentlichung zeitweise oder endgültig
                            einzustellen. Haftungsausschluss für Links Verweise und
                            Links auf Webseiten Dritter liegen ausserhalb des
                            Verantwortungsbereichs der Veröffentlichenden . Es wird
                            jegliche Verantwortung für solche Webseiten abgelehnt. Der
                            Zugriff und die Nutzung solcher Webseiten erfolgen auf
                            eigene Gefahr der jeweiligen nutzenden Person. Urheberrechte
                            Die Urheber- und alle anderen Rechte an Inhalten, Bildern,
                            Fotos oder anderen Dateien auf den Webseiten der
                            Veröffentlichenden, gehören ausschliesslich den
                            Veröffentlichenden oder den speziell genannten
                            Rechteinhabern. Für die Reproduktion jeglicher Elemente ist
                            die schriftliche Zustimmung des Urheberrechtsträgers im
                            Voraus einzuholen.`,
      dataProtectionAgreement: "Datenschutzerklärung",
      dataProtectionAgreementText: `Die Datenschutzerklärung der Veröffentlichenden regelt die
                            Erhebung und Verarbeitung von Personendaten auf den
                            jeweiligen Webseiten. Sie kann hier eingesehen werden:`,
      additionalDataProtectionAgreements: `Datenschutzerklärungen der verwendeten Drittanbieter
                                    erbrachte Funktionen und Nutzungsstatistiken`,
      furtherNotices: `Weitere Bestimmungen`,
    },
    GtcDisplayPage: {
      title: "Allgemeine Geschäftsbedingungen des VSETH",
      body: "Dieser Seite bietet Ihnen einen zentralen Ort, an dem du die Datenschutzerklärung und die AGBs, die der VSETH Vorstand erlassen hat, einsehen können.",
      thead: "Geschäftsbedingungen",
      tHeadButton: "Auswahl",
      privacyPolicy: "Datenschutzerklärung des VSETH",
      gtcMusicrooms: "AGB für die Nutzung der Musikzimmer ",
      gtcOnlinePayment: "AGB für die Online-Zahlungsplattform",
    },
  },
};
