import React from "react";
import { Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";

// TODO: add to vseth compoments?
export const LoadScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center" style={{ height: "80vh" }}>
      <form className="text-center my-auto">
        <p>{t("LoadScreen.loadingData")}</p>
        <Loader />
      </form>
    </div>
  );
};
