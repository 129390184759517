import { Icon, ICONS } from "vseth-canine-ui";
import React, { useState } from "react";

interface Props {
  children: React.ReactNode;
  htmlFor: string;
  hide?: boolean;
  onClick?: () => void;
}

/**
 * Label with a clickable ? icon.
 * Icon can be hidden by setting the hide prop to true.
 * @param props
 */
const LabelWithExplanationIcon: React.FC<Props> = (props) => {
  const [opacity, setOpacity] = useState(0.6);

  const increaceOpacity = () => setOpacity(1);
  const decreaseOpacity = () => setOpacity(0.6);

  return (
    <div>
      <label htmlFor={props.htmlFor}>{props.children}</label>
      {!props.hide && (
        <div
          onClick={(_) => props.onClick && props.onClick()}
          onMouseEnter={(_) => increaceOpacity()}
          onMouseLeave={(_) => decreaseOpacity()}
          style={{ float: "right", opacity: opacity }}
        >
          <Icon icon={ICONS.HELP} size="1em" />
        </div>
      )}
    </div>
  );
};

export default LabelWithExplanationIcon;
