import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ErrorScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center" style={{ height: "80vh" }}>
      <form className="text-center my-auto">
        <h1>{t("ErrorScreen.title")}</h1>
        <p>{t("ErrorScreen.body")}</p>
        <Link to="">{t("Navbar.home")}</Link>
      </form>
    </div>
  );
};
