import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useFormikContext } from 'formik';
import { Grid } from '@mantine/core';

interface Props {
  name: string,
  value: string,
  className: string,
}

const PhoneInputField = ({ ...props }: Props) => {
  const formik = useFormikContext();

  return (
    <Grid grow={true}>
      <Grid.Col>
        <PhoneInput
          className={props.className}
          value={props.value}
          defaultCountry="CH"
          onChange={(value) => formik.setFieldValue(props.name, value)}
          onBlur={() => formik.setFieldTouched(props.name, true, true)}
        />
      </Grid.Col>
    </Grid>
  );
};

export default PhoneInputField;

