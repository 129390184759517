/*
File defining all imprints that can be shown by accounts.
If an imprint is not defined, a default (0100-vseth) will be used.
*/

// Helper type that matches a language code (e.g. "de") to the text in that language
// Must contain "en" text as it is used as fallback
export type MultiLingualText = Record<string, string>;

// Returns text in language specified
// Defaults to "en" if language is not provided for this text
export const getTextInLang = (texts: MultiLingualText, lang: string): string =>
  lang in texts ? texts[lang] : texts.en;

export interface AdditionalDataProtectionAgreements {
  title: string;
  texts?: MultiLingualText;
  link?: string;
}

export interface DefaultImprint {
  legalEntityName: string;
  privacyPolicyLink: string;
  contact: string;
  contactEmail: string;
  legalRepresentatives: string[];
  additionalDataProtectionAgreements: AdditionalDataProtectionAgreements[];
  furtherNotices?: MultiLingualText;
}

export interface CustomImprint {
  link: string;
}

export const Imprints: Record<string, CustomImprint | DefaultImprint> = {
  "vseth-0100-verband": {
    legalEntityName: "VSETH - Verband der Studierenden an der ETH",
    privacyPolicyLink: "https://account.vseth.ethz.ch/privacy",
    contact: "Universitätstrasse 6\n8092 Zürich\nSchweiz",
    contactEmail: "vseth@vseth.ethz.ch",
    legalRepresentatives: ["Nic Cantieni", "Alexander Beck"],
    additionalDataProtectionAgreements: [
      {
        title: "Matomo",
        texts: {
          de: "Wir setzen eine selbst betrieben Instanz von Matomo(https://matomo.org) ein. Mittels Matomo koennen wir grundlegende Analytics Daten wie Page View analysieren. Ziel ist es zu verstehen wo Probleme in der Bedienbarkeit unser Website liegen, um diese Aspekte für die Zukunft zu verbessen. Die Daten stehen innerhalb des VSETH nur einem kleinen Personenkreis zur Verfügung und werden ausschliesslich zur Verbesserung der Bedienbarkeit unserer zukünftigen Web-Applikationen verwendet.",
          en: "We use a self - hosted instance of Matomo(https://matomo.org/) to collect basic analytic data such as page views on this site. The server rejects opt-out settings in your browser for tracking. The aim is to understand where problems lie in the usability of our website and to improve these aspects for the future. The data is only available to a small group of people within VSETH and is used exclusively to improve the usability of our future web applications.",
        },
      } as AdditionalDataProtectionAgreements,
    ],
  } as DefaultImprint,
  "vseth-1116-vis": {
    link: "https://vis.ethz.ch/impressum",
  } as CustomImprint,
};

export default Imprints;
