import React from "react";
import { Container } from "@mantine/core";
import Imprints, {
  CustomImprint,
  DefaultImprint,
  getTextInLang,
} from "../imprint/imprints";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface ImprintProps  {}

// Simple "form letter" like page to show imprints for
// different organizations or redirect to an imprint
export const ImprintPage: React.FC<ImprintProps> = (props) => {
  const { t, i18n } = useTranslation();
  const currLang = i18n.language;

  let imprintRaw;
  const { org } = useParams<{ org: string }>();

  if (org !== undefined && org in Imprints) {
    imprintRaw = Imprints[org];
  } else {
    imprintRaw = Imprints["vseth-0100-verband"];
  }

  // Differentiate between default and custom imprint
  if ("link" in imprintRaw) {
    const redirectImprint = imprintRaw as CustomImprint;
    window.location.href = redirectImprint.link;
  }

  const imprint = imprintRaw as DefaultImprint;

  return (
    <Container>
      <section className="mb-5">
        <h1 className="mb-3">{t("Imprint.title")}</h1>
        <p>{t("Imprint.intro")}</p>
        <div style={{ lineHeight: 0.6 }}>
          <p>{imprint.legalEntityName}</p>
          {imprint.contact.split("\n").map((c) => {
            return <p> {c} </p>;
          })}
        </div>
        <p>E-Mail: {imprint.contactEmail}</p>
        <h2>{t("Imprint.legalRepresentatives")}</h2>
        <ul>
          {imprint.legalRepresentatives.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
        <h2>{t("Imprint.disclaimer")}</h2>
        <p>{t("Imprint.disclaimerText")}</p>
        <h2>{t("Imprint.dataProtectionAgreement")}</h2>
        <p>
          {t("Imprint.dataProtectionAgreementText")}{" "}
          <a
            href={imprint.privacyPolicyLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {imprint.privacyPolicyLink}
          </a>{" "}
        </p>
        {imprint.additionalDataProtectionAgreements.length >= 1 && (
          <>
            <h3>{t("Imprint.additionalDataProtectionAgreements")}</h3>
            {imprint.additionalDataProtectionAgreements.map((e) => (
              <>
                <h4>{e.title}</h4>
                {e.link && (
                  <a href={e.link} rel="noopener noreferrer" target="_blank">
                    {e.link}
                  </a>
                )}
                <p>{e.texts && getTextInLang(e.texts, currLang)}</p>
              </>
            ))}
          </>
        )}
        {imprint.furtherNotices && (
          <>
            <h2>{t("Imprint.furtherNotices")}</h2>
            <p>{getTextInLang(imprint.furtherNotices, currLang)}</p>
          </>
        )}
      </section>
    </Container>
  );
};
