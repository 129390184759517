export const i18n_english = {
  translation: {
    lang: "en",
    example: {
      title: "Translations are important!",
      introduction:
        "A lot of members of ETH Zurich do not speak german, so it is very important for the IT to tools that can be easily translated into multiple languages. This template showcases how this can be achieved.",
      buttonText: "Change language",
    },
    buttons: {
      edit: "Edit",
      save: "Save Changes",
      cancel: "Cancel",
      back: "Back",
      close: "Close",
      reload: "Reload",
      addPostalAddress: "Add Postal Address",
      addBankAccount: "Add Bank Account",
      addEmploymentInformation: "Add Employment Information",
      display: "Display",
    },
    formValidation: {
      required: "Required",
      tooShort: "Too short",
      nameInvalid: "Invalid Name",
      emailInvalid: "Invalid Email",
      phoneNumberInvalid: "Invalid Phone Number",
      streetAddressInvalid: "Invalid Street Address",
      localityInvalid: "Invalid Locality",
      postalCodeInvalid: "Postal Code invalid",
      ibanInvalid: "IBAN invalid: format e.g. CHXXXXXXXXXXXXXXXXXXX (only SEPA countries are supported)",
      bicInvalid: "BIC invalid",
      ahvNumberInvalid:
        "AHV number invalid - must be of the for '756.XXXX.XXXX.XX'",
      zemisInvalid: "ZEMIS number invalid - must be of the for 'XXXX.XXXX-X'",
    },
    App: {
      name: "VSETH Account"
    },
    Navbar: {
      home: "Home",
      account: "Account",
      legal: "Legal",
    },
    Footer: {
      impressum: "Imprint",
      privacyPolicy: "Privacy Policy",
    },
    ErrorScreen: {
      title: "An error occurred.",
      body: "Try reloading the page and contact the administrator if the error persists.",
    },
    LoadScreen: {
      loadingData: "Data is loading",
    },
    HelloPage: {
      title: "Welcome to VSETH Account",
      body: "This service provides you a centralized place to manage all your VSETH data.",
      toaccount: "To your account",
    },
    AccountPage: {
      accountInfo: "Account Information",
      basicInfo: "Basic Information",
      username: "Username",
      firstName: "First Name",
      lastName: "Last Name",
      telephone: "Phone Number",
      email: "Email",
      birthdate: "Birthday",
      swissEduIdentifier: "Swiss Edu Identifier",
      swissEduPersonAffiliation: "Swiss Edu Person Affiliation",
      studentNumber: "Student Number",
      studySemester: "Semester",
      ethFachId: "ETH Subject",
      groupMembership: "Group Memberships",
      address: "Address",
      street: "Street",
      locality: "City",
      postalCode: "Postal Code",
      country: "Country",
      bankAccount: "Bank Account Information",
      accountHolderName: "Account Holder Name",
      iban: "IBAN",
      bic: "BIC",
      bankName: "Bank Name",
      bankingAddress: "Address provided to Bank",
      noBankingAddress: "No specified",
      employmentInformation: "Employment Information",
      nationality: "Nationality",
      ahvNumber: "AHV Number",
      zemisNumber: "ZEMIS Number",
      numberOfKids: "Number of Children",
      numberOfKidsUnspecified: "Not specified",
      vsethMembership: {
        title: "VSETH Membership",
        exitDate: "VSETH Membership Exit Date",
        noExitDate: "Not yet exited",
        member: "Member",
        notMember: "Not a member",
        unspecified: "Not specified",
      },
      vsethMembershipType: {
        title: "VSETH Membership Type",
        bachelorOrMaster: "Ordinary Membership",
        phd: "PhD Student Membership",
        vsethEmployees: "VSETH Employee Membership",
        ethApprentice: "ETH Apprentice Membership",
        mobilityStudent: "Mobility Student Membership",
        inContinuingEducation: "Continuing Education Student Membership",
        auditor: "Auditor Membership",
        unspecified: "Not specified",
      },
      civilStatus: {
        title: "Civil Status",
        divorced: "Divorced",
        married: "Married",
        separated: "Separated",
        widowed: "Widowed",
        unmarried: "Unmarried",
        registeredPartnership: "Registered Partnership",
        dissolvedPartnership: "Dissolved Partnership",
        unspecified: "Not specified",
      },
      residencePermit: {
        title: "Residence Permit",
        bPermit: "B Permit",
        cPermit: "C Permit",
        unspecified: "Not specified",
      },
      confession: {
        none: "None",
        catholic: "Catholic",
        christCatholic: "Christan Catholic",
        protestant: "Protestant",
        unspecified: "Not specified",
      },
    },
    AccountEditPage: {
      editBasicInfo: "Edit Basic Information",
      editAddress: "Edit Postal Address",
      editBankingInfo: "Edit Banking Information",
      editEmploymentInformation: "Edit Employment Information",
    },
    EditBasicInfo: {
      firstName: "First Name",
      lastName: "Last Name",
      birthdate: "Birthday",
      email: "Email",
      telephone: "Phone Number",
    },
    EditAddress: {
      streetAddress: "Street Address incl. Number",
      locality: "City",
      postalCode: "Postal Code",
      country: "Country",
    },
    EditBankAccount: {
      bankAccountInformation: "Bank Account Information",
      accountHolderName: "Account Holder Name",
      bankName: "Bank Name",
      iban: "IBAN",
      bic: "BIC",
      bankingAddress: "Address Registered at Bank",
      streetAddress: "Street Address inkl. Number",
      locality: "City",
      postalCode: "Postal Code",
      country: "Country",
    },
    EditEmploymentInformation: {
      nationality: "Nationality",
      ahvNumber: "AHV Number",
      zemisNumber: "ZEMIS Number",
      numberOfKids: "Number of Children",
      civilStatus: {
        title: "Civil Status",
        divorced: "Divorced",
        married: "Married",
        separated: "Separated",
        widowed: "Widowed",
        unmarried: "Unmarried",
        registeredPartnership: "Registered Partnership",
        dissolvedPartnership: "Dissolved Partnership",
        unspecified: "Not specified",
      },
      residencePermit: {
        title: "Residence Permit",
        bPermit: "B Permit",
        cPermit: "C Permit",
        unspecified: "Not specified",
      },
      confession: {
        none: "None",
        catholic: "Catholic",
        christCatholic: "Christan Catholic",
        protestant: "Protestant",
        unspecified: "Not specified",
      },
    },
    Explanations: {
      oisHeader: "External Information",
      oisBody: `This information is not managed directly by VSETH. 
        If you want to change it, please do so at your university or, if you are not a student, on your SWITCH account.`,
      vsethInformationHeader: "VSETH Information",
      vsethInformationBody: `This information is managed by VSETH. 
        If you want to change it, you can do so by clicking the edit button below.`,
      vsethFixedInformationHeader: "VSETH Information",
      vsethFixedInformationBody: `This information is managed by VSETH and cannot be changed.`,
      uniFieldHeader: "University Information",
      uniFieldBody:
        "This information is provided by your university and cannot be changed.",
    },
    Imprint: {
      title: "Imprint",
      intro: `This imprint represents the legally required
                    indication of the origin of all our websites, in order to identify the
                    responsible for the content, hereinafter referred to as the
                    publishers, to make them recognizable.
                    This English version was created for your convenience but only the German one is binding.`,
      contactAddress: "Contact Address",
      legalRepresentatives: "Representatives",
      disclaimer: "Disclaimer",
      disclaimerText: `The publishers do not assume any guarantee
                    the correctness, accuracy, timeliness, reliability and completeness of the
                    accuracy, timeliness, reliability and completeness of the
                    information. Liability claims against the
                    for damages of a material or immaterial nature arising from the
                    immaterial nature arising from access to, use or non-use of the information
                    use or non-use of the published information, through misuse of the
                    misuse of the connection or technical faults are excluded.
                    are excluded. All offers are
                    non-binding. The publishers reserve the right to
                    parts of the pages or the complete publication including all offers and information.
                    or to discontinue the publication temporarily or permanently.
                    cease publication. Disclaimer for links References and
                    links to third party websites lie outside the
                    responsibility of the publishers. It is
                    any responsibility for such websites., reliability an The
                    access and use of such websites is at the
                    own risk of the respective using person. Copyrights
                    The copyrights and all other rights to content, images,
                    photos or other files on the websites of the
                    the web pages of the publishers, belong exclusively to the
                    the publishers or the specifically named
                    holders of the rights. For the reproduction of any elements
                    the written consent of the copyright holder in advance.
                    obtained in advance.`,
      dataProtectionAgreement: "Privacy Policy",
      dataProtectionAgreementText: `The privacy policy of the publishers regulates the
                    collection and processing of personal data on the
                    respective websites. It can be viewed here:`,
      additionalDataProtectionAgreements: `Privacy statements of third party providers used
                            and usage statistics`,
      furtherNotices: `Further Notices`,
    },
    GtcDisplayPage: {
      title: "General Terms and Conditions by the VSETH",
      body: "This page provides you with a central location to view the data protection agreement and all of the T&Cs that the VSETH Executive Board has enacted.",
      thead: "Terms and Conditions",
      tHeadButton: "View",
      privacyPolicy: "Privacy Policy of VSETH",
      gtcMusicrooms: "GTC for the use of the music rooms ",
      gtcOnlinePayment: "GTC for the online payment platform ",
    },
  },
};
