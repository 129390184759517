import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, Loader, Table } from "@mantine/core";
import ReactMarkdown from "react-markdown";
import i18n from "../locals/i18n";
import { useGetGtcDocumentQuery } from "../features/gtc/gtcSlice";

export const GtcDisplayPage = () => {
  const { t } = useTranslation();
  const currLang = i18n.language;

  const [gtcKey, setGtcKey] = useState("privacyPolicy");

  const languageParam = currLang === "de" ? "de" : "en";
  const {
    data: markdown,
    isLoading,
    isFetching,
  } = useGetGtcDocumentQuery({
    gtc: gtcKey,
    lang: languageParam,
  });

  return (
    <Container>
      <section className="mb-5">
        <h1 className="mb-3">{t("GtcDisplayPage.title")}</h1>
        <p>{t("GtcDisplayPage.body")}</p>
        <Table>
          <thead>
            <th className="col-mb-9">{t("GtcDisplayPage.thead")}</th>
            <th className="col-mb-3">{t("GtcDisplayPage.tHeadButton")}</th>
          </thead>
          <tbody>
            <tr>
              <td>{t("GtcDisplayPage.privacyPolicy")}</td>
              <td>
                <Button
                  color="primary"
                  className="mb-3"
                  disabled={gtcKey === "privacyPolicy"}
                  onClick={() => setGtcKey("privacyPolicy")}
                >
                  {t("buttons.display")}
                </Button>
              </td>
            </tr>
            <tr>
              <td>{t("GtcDisplayPage.gtcMusicrooms")}</td>
              <td>
                <Button
                  color="primary"
                  className="mb-3"
                  disabled={gtcKey === "musicroom"}
                  onClick={() => setGtcKey("musicroom")}
                >
                  {t("buttons.display")}
                </Button>
              </td>
            </tr>
            <tr>
              <td>{t("GtcDisplayPage.gtcOnlinePayment")}</td>
              <td>
                <Button
                  color="primary"
                  className="mb-3"
                  disabled={gtcKey === "onlinePayment"}
                  onClick={() => setGtcKey("onlinePayment")}
                >
                  {t("buttons.display")}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </section>
      <hr />
      <section className="mb-5">
        {isLoading || isFetching ? (
          <Loader color="primary" />
        ) : (
          <ReactMarkdown children={markdown || ""} />
        )}
      </section>
    </Container>
  );
};
