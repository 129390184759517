import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { User } from "../../features/user/userSlice";
import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import {
  dateObjToLocalDate,
  dateToDateObj,

} from "../../util/proto";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import DatePickerField from "../DatePickerField";
import LabelWithExplanationIcon from "../LabelWithExplanationIcon";
import { regexConstants } from "../../util/regexConstants";
import PhoneInputField from "./PhoneInputField";

interface EBIProps {
  user: User;
  disableOISFields?: boolean;
  onSubmit: (u: User) => void;
  setExplanation: (key: string) => void;
  isUpdating?: boolean;
}

// EditBasicInfo is a form to edit basic user information
// "Basic" refers to all fields besides address, bank account, and employment information
export const EditBasicInfo: React.FC<EBIProps> = ({
  user,
  onSubmit,
  disableOISFields = false,
  setExplanation,
  isUpdating = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        firstName: user.givenName,
        lastName: user.familyName,
        birthdate: dateObjToLocalDate(user.birthdate),
        email: user.email,
        telephone: user.telephone,
      }}
      validateOnMount={true}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .trim()
          .matches(regexConstants.name, t("formValidation.nameInvalid"))
          .required(t("formValidation.required")),
        lastName: Yup.string()
          .trim()
          .matches(regexConstants.name, t("formValidation.nameInvalid"))
          .required(t("formValidation.required")),
        birthdate: Yup.date()
          .nullable()
          // there is not really a good way to have nullable dates with yup, see https://github.com/jquense/yup/issues/764#issuecomment-651131728
          .transform((curr, orig) => (orig === "" ? null : curr)),
        email: Yup.string()
          .trim()
          .email(t("formValidation.emailInvalid"))
          .required(t("formValidation.required")),
        telephone: Yup.string()
          .trim()
          .test(
            "is-phonenumber",
            t("formValidation.phoneNumberInvalid"),
            (value) => {
              // empty value is valid by default
              if (!value) {
                return false;
              }

              // hacky: phone input field does not return the prefix correctly
              const str = value.startsWith("+") ? value : "+" + value;
              const phoneNumber = parsePhoneNumberFromString(str);
              return phoneNumber !== undefined && phoneNumber.isValid();
            }
          )
          .required(t("formValidation.required")),
      })}
      onSubmit={(values, formikHelpers) => {
        const updatedInfo: User = {
          ...user,
          birthdate: values.birthdate
            ? dateToDateObj(values.birthdate)
            : undefined,
          telephone: values.telephone.startsWith("+")
            ? values.telephone
            : "+" + values.telephone,
        };
        onSubmit(updatedInfo);
      }}
    >
      {(formik) => (
        <div className="col-lg-12">
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <LabelWithExplanationIcon
                htmlFor="firstName"
                onClick={() => setExplanation("oisField")}
              >
                {t("EditBasicInfo.firstName")}
              </LabelWithExplanationIcon>
              <Field
                name="firstName"
                type="text"
                disabled={true}
                className={`form-control ${formik.touched.firstName && formik.errors.firstName
                    ? "is-invalid"
                    : ""
                  }`}
              />
              <ErrorMessage
                component="span"
                name="firstName"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <LabelWithExplanationIcon
                htmlFor="lastName"
                onClick={() => setExplanation("oisField")}
              >
                {t("EditBasicInfo.lastName")}
              </LabelWithExplanationIcon>
              <Field
                name="lastName"
                type="text"
                disabled={true}
                className={`form-control ${formik.touched.lastName && formik.errors.lastName
                    ? "is-invalid"
                    : ""
                  }`}
              />
              <ErrorMessage
                component="span"
                name="lastName"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <LabelWithExplanationIcon
                htmlFor="birthdate"
                onClick={() => setExplanation("vsethField")}
              >
                {t("EditBasicInfo.birthdate")}
              </LabelWithExplanationIcon>
              <Field
                name="birthdate"
                as={DatePickerField}
                // selected={formik.values.birthdate === "Not specified" ? new Date("01-01-2000") : formik.values.birthdate}
                selected={formik.values.birthdate}
                className={`form-control ${formik.touched.birthdate && formik.errors.birthdate
                    ? "is-invalid"
                    : ""
                  }`}
              />
              <ErrorMessage
                component="span"
                name="birthdate"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <LabelWithExplanationIcon
                htmlFor="email"
                onClick={() => setExplanation("oisField")}
              >
                {t("EditBasicInfo.email")}
              </LabelWithExplanationIcon>
              <Field
                name="email"
                type="text"
                disabled={true}
                className={`form-control ${formik.touched.email && formik.errors.email
                    ? "is-invalid"
                    : ""
                  }`}
              />
              <ErrorMessage
                component="span"
                name="email"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <LabelWithExplanationIcon
                htmlFor="telephone"
                onClick={() => setExplanation("vsethField")}
              >
                {t("EditBasicInfo.telephone")}
              </LabelWithExplanationIcon>
              <Field
                name="telephone"
                as={PhoneInputField}
                country={"ch"}
                inputProps={{ name: "telephone" }}
                onChange={(value: string) => {
                  formik.setValues({ ...formik.values, telephone: value });
                }}
                className={`form-control ${formik.touched.telephone && formik.errors.telephone
                    ? "is-invalid"
                    : ""
                  }`}
              />
              <ErrorMessage
                component="span"
                name="telephone"
                className="text-danger"
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                color="secondary"
                className="m-2"
                onClick={() => navigate("/account")}
              >
                {t("buttons.cancel")}
              </Button>
              <Button
                color="primary"
                type="submit"
                className="m-2"
                loading={isUpdating}
                disabled={!formik.dirty || !formik.isValid}
              >
                {t("buttons.save")}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
