import {
  BankAccount as BankAccountMessage,
  EmploymentInformation as EmploymentInformationMessage,
  EthStudyInfo as EthStudyInfoMessage,
  GetUserRequest,
  PostalAddress as PostalAddressMessage,
  UpdateUserRequest,
  User as UserMessage,
  VsethMembershipInfo as VsethMembershipInfoMessage,
  VsethMembershipType,
} from "../../proto/sip/people/people_pb";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { getAuthMetadata, userToUserMessage } from "../../util/proto";
import { AuthSliceRoot } from "../auth/authSlice";
import { Metadata } from "grpc-web";
import * as jspb from "google-protobuf";

import { BaseQueryFn, createApi, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { PeoplePromiseClient } from "../../proto/sip/people/people_grpc_web_pb";

export type User = UserMessage.AsObject;
export type PostalAddress = PostalAddressMessage.AsObject;
export type VsethMembershipInfo = VsethMembershipInfoMessage.AsObject;
export type EthStudyInfo = EthStudyInfoMessage.AsObject;
export type BankAccount = BankAccountMessage.AsObject;
export type EmploymentInformation = EmploymentInformationMessage.AsObject;

export interface GrpcBaseQueryArgs<
  T extends PeoplePromiseClient
> {
  client: T;
  prepareMetadata?: (
    metadata: Metadata,
    api: { getState: () => unknown }
  ) => Metadata;
}

export interface GrpcQueryArgs<
  T extends PeoplePromiseClient
> {
  message: jspb.Message;
  method: keyof T;
}

type Meta = {
  requestId: string;
};

const getGrpcBaseQuery =
  ({
    client,
    prepareMetadata,
  }: GrpcBaseQueryArgs<PeoplePromiseClient>): BaseQueryFn<
    GrpcQueryArgs<PeoplePromiseClient>,
    unknown,
    FetchBaseQueryError,
    {},
    Meta & FetchBaseQueryMeta
  > =>
    async (req, api) => {
      let metadata: Metadata = {};
      if (prepareMetadata) {
        metadata = prepareMetadata(metadata, api);
      }

      const res = client[req.method](req.message as any, metadata);

      return res
        .then((result) => {
          return { data: result };
        })
        .catch((err) => {
          return { error: err };
        });
    };

let client = new PeoplePromiseClient(
  "https://people.web.api.getsip.ethz.ch",
  {},
  {}
);


export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: getGrpcBaseQuery({
    client,
    prepareMetadata: (metadata, { getState }) => {
      const token = (getState() as AuthSliceRoot).auth.tokens?.token;
      return {
        ...metadata,
        ...getAuthMetadata(token),
      };
    },
  }),
  tagTypes: ["Users", "PeopleAuthorizationRole"],
  endpoints: (builder) => ({
    getUser: builder.query<User, {name: string} >({
      query: ({name}) => {
        const message = new GetUserRequest();
        message.setView(UserMessage.View.INCLUDE_MEMBERSHIPS);
        message.setName(name);
        return {
          message,
          method: "getUser",
        };
      },
      transformResponse: (res: UserMessage) =>
        res.toObject(),
      providesTags: (user) => [
        { type: "Users", id: user && user.name },
      ],
    }),
    updateUser: builder.mutation<User, {paths: string[], user: User}>({
      query: ({ paths, user }) => {
        const message = new UpdateUserRequest();
        const userMessage = userToUserMessage(user);
        const fieldMask = new FieldMask();
        fieldMask.setPathsList(paths);
        message.setUser(userMessage);
        message.setName(user.name);
        message.setAuditMessage("Update by end-user through account.vseth.ethz.ch");
        message.setUpdateMask(fieldMask);

        return {
          message,
          method: "updateUser",
        };
      },
      transformResponse: (res: UserMessage) =>
        res.toObject(),
      invalidatesTags: (user) =>
        user
          ? [
            { type: "Users", id: user.name },
          ]
          : [],
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
} = userApi;


export const selectDisableOISFieldsForUser = (user: User) => {
  if (!user) {
    return false;
  }

  // VSETH employees are vseth members and they are allowed to change their settings through the accounts page.
  const membershipInfo = user.vsethMembershipInfo;
  if (!membershipInfo) {
    return false;
  }
  const membershipType = membershipInfo.membershipType;
  return (
    membershipType ===
    VsethMembershipType.VSETH_MEMBERSHIP_TYPE_BACHELOR_OR_MASTER ||
    membershipType === VsethMembershipType.VSETH_MEMBERSHIP_TYPE_PHD ||
    membershipType ===
    VsethMembershipType.VSETH_MEMBERSHIP_TYPE_MOBILITY_STUDENTS ||
    membershipType ===
    VsethMembershipType.VSETH_MEMBERSHIP_TYPE_STUDENTS_IN_CONTINUING_EDUCATION ||
    membershipType === VsethMembershipType.VSETH_MEMBERSHIP_TYPE_ETH_APPRENTICES
  );
};